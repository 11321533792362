import React from "react";
function Index() {
    return (
        <div className="container mx-auto pt-16">
            <div className="bg-gray-100 xl:px-20 lg:px-20 md:px-12 pt-10 pb-32 sm:pb-0">
                <h1 className="text-center xl:text-4xl text-3xl font-extrabold text-gray-800 w-5/6 mx-auto">New Talent Models Grooming School</h1>
                <p className="text-lg text-center text-gray-600 w-5/6 mx-auto pt-5 pb-24">A leading institution in fashion, specialising in
transforming aspiring models into professionals. Our tailored curriculum enhances individual
strengths, offering comprehensive training to meet industry demands. With personalised mentorship
from experienced instructors, we foster confidence and professionalism while embracing creativity.
Through practical and theoretical education, graduates gain the skills needed for success in today's
competitive landscape. We provide ongoing support and networking opportunities, ensuring
students emerge as respected talents ready to make meaningful contributions to the fashion
industry.</p>
            </div>
            <div className="xl:flex xl:w-9/12 lg:flex md:flex bg-white w-4/5 flex-wrap shadow-lg mx-auto -mt-16 rounded">
                <div className="w-full xl:w-3/12 lg:w-3/12 md:w-3/12 bg-white py-8 rounded-bl rounded-tl xl:border-r lg:border-r sm:border-r md:border-r border-gray-400 border-b xl:border-b-0 lg:border-b-0 md:border-b-0 sm:border-b-0">
                    <p className="text-2xl text-gray-800 text-center">Students Trained</p>
                    <p className="text-5xl font-bold text-center text-indigo-700 pt-1">10000+</p>
                </div>
                <div className="w-full xl:w-3/12 lg:w-3/12 md:w-3/12 bg-white py-8 xl:border-r lg:border-r sm:border-r md:border-r border-gray-400 border-b xl:border-b-0 lg:border-b-0 md:border-b-0 sm:border-b-0">
                    <p className="text-2xl text-gray-800 text-center">Successful Batches</p>
                    <p className="text-5xl font-bold text-center text-indigo-700 pt-1">400+</p>
                </div>
                <div className="w-full xl:w-3/12 lg:w-3/12 md:w-3/12 bg-white py-8 xl:border-r lg:border-r sm:border-r md:border-r border-gray-400 border-b xl:border-b-0 lg:border-b-0 md:border-b-0 sm:border-b-0">
                    <p className="text-2xl text-gray-800 text-center">Mentors & Guides</p>
                    <p className="text-5xl font-bold text-center text-indigo-700 pt-1">15+</p>
                </div>
                <div className="w-full xl:w-3/12 lg:w-3/12 md:w-3/12 bg-white py-8 rounded-br rounded-tr pt-8 pb-8">
                    <p className="text-2xl text-gray-800 text-center">Years in Industry</p>
                    <p className="text-5xl font-bold text-center text-indigo-700 pt-1">10+</p>
                </div>
            </div>
        </div>
    );
}

export default Index;
