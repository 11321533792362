import React from "react";

function Index() {
    return (
        <>
            <div className="container mx-auto md:pt-6 pt-14 px-4">
                <div className="flex flex-wrap">
                    {/* Image Section */}
                    <div className="md:w-2/5 w-full mb-6 md:mb-0">
                        <div className="rounded overflow-hidden">
                            <img src="/hero.png" alt="Background" className="object-contain h-64 w-full md:h-auto" />
                        </div>
                    </div>

                    {/* Text Section */}
                    <div className="md:w-3/5 w-full flex md:pl-6">
                        <div className="rounded border-gray-300 text-center p-8 dark:border-gray-700 w-full">
                        <h2 className="text-4xl lg:text-5xl font-extrabold text-center pt-14">My Profile</h2>
                            <p className="text-md text-left mt-14 text-gray-600 tracking-wide font-thin text-xl">
                                 As a <span className="text-4xl">choreographer</span>, I create impactful fashion shows that reflect the unique vision of each client, ensuring unforgettable experiences. My expertise in model selection, runway direction, and event production ensures a flawless execution.
                            </p>
                            
                            <p className="text-md text-left mt-14 text-gray-600 tracking-wide font-thin text-xl">
                                 As a <span className="text-4xl">model mentor</span>, I train aspiring models in essential skills like posing, walking, and building self-confidence to succeed in the competitive fashion industry.
                            </p>
                           
                            <p className="text-md text-left mt-14 text-gray-600 tracking-wide font-thin text-xl">
                            As a <span className="text-4xl">lifestyle & image coach</span>, I help clients refine their image, communication, and personal style to align with their professional goals, delivering holistic coaching that leads to lasting success.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Index;
