import React from "react";

function index() {
  return (
    <>
      <div className="px-4 py-12">

      <h1 role="heading" tabIndex={0} className="text-6xl font-bold 2xl:leading-10 leading-0 text-center text-gray-800">
      Glimpse with Bollywood Celebrities</h1>
        <div className="lg:max-w-[1440px] md:max-w-[744px] max-w-[375px] py-12 lg:px-8 md:px-6 px-4 mx-auto bg-white">
          

          
          
          <div className="flex gap-x-8">
          
            <div aria-label="Image-area-one">
              <div aria-label="one">
                <img
                  className="hidden lg:block md:hidden"
                  src="/celebs/18.jpg"
                  alt="book-1"
                />
              </div>
              {/* <div aria-label="two" className="my-6 xl:my-8">
                <img
                  className="hidden lg:block md:hidden"
                  src="/celebs/18.jpg"
                  alt="girl-2"
                />
              </div> */}
            </div>
            <div aria-label="Image-area-two" className="relative group h-full">
              <img
                className="hidden lg:block md:hidden"
                src="/celebs/17.jpg"
                alt="girl"
              />
             
            </div>
            <div aria-label="Image-area-three">
              <div aria-label="one">
                <img
                  className="hidden lg:block md:hidden"
                  src="/celebs/19.jpg"
                  alt="girl-1"
                />
              </div>
              {/* <div aria-label="two" className="my-6 xl:my-8">
                <img
                  className="hidden lg:block md:hidden"
                  src="https://tuk-cdn.s3.amazonaws.com/can-uploader/girl100.png"
                  alt="girl-2"
                />
              </div> */}
            </div>
            <div aria-label="Image-area-four">
              <img
                className="hidden lg:block md:hidden"
                src="/celebs/16.jpg"
                alt="black-dress-girl"
              />
            </div>
            
          </div>
          <div className="flex gap-x-6">
            <div aria-label="Image-area-one">
              <div aria-label="one">
                <img
                  className="hidden lg:hidden md:block"
                  src="/celebs/17.jpg"
                  alt="book-1"
                />
              </div>
              {/* <div aria-label="two" className="my-6">
                <img
                  className="hidden lg:hidden md:block"
                  src="https://tuk-cdn.s3.amazonaws.com/can-uploader/212121.png"
                  alt="girl-2"
                />
              </div> */}
            </div>
            <div aria-label="Image-area-two" className="relative group">
              <img
                className="hidden lg:hidden md:block"
                src="/celebs/17.jpg"
                alt="girl"
              />
              
            </div>
          </div>
          <div className="flex gap-x-6">
            <div aria-label="Image-area-one">
              <div aria-label="one">
                <img
                  className="hidden lg:hidden md:block"
                  src="/celebs/18.jpg"
                  alt="girl-1"
                />
              </div>
              {/* <div aria-label="two" className="my-6">
                <img
                  className="hidden lg:hidden md:block"
                  src="https://tuk-cdn.s3.amazonaws.com/can-uploader/ipad22.png"
                  alt="girl-2"
                />
              </div> */}
            </div>
            {/* <div aria-label="Image-area-two">
              <img
                className="hidden lg:hidden md:block"
                src="https://tuk-cdn.s3.amazonaws.com/can-uploader/ipad33.png"
                alt="girl-with-black-dress"
              />
            </div> */}
          </div>
          <div className="flex gap-x-6">
            <div aria-label="Image-area-one">
              <img
                className="hidden lg:hidden md:block"
                src="/celebs/19.jpg"
                alt="sandel-1"
              />
            </div>

          </div>
          
        </div>
      </div>
    </>
  );
}

export default index;
