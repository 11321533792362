import React from "react";

function MyApp() {
    return (
        <div className="bg-gray-50 py-20 flex flex-col items-center justify-center">
            <div className="xl:w-1/2 w-11/12">
                <h1 role="heading" tabIndex={0} className="text-6xl font-bold 2xl:leading-10 leading-0 text-center text-gray-800">
                    Mentorship & Guidance
                </h1>
                <h2 role="contentinfo" tabIndex={0} className="text-base leading-normal text-center text-gray-600 mt-5">
                Providing streamlined guidance to
aspiring models to enhance their skills and
build their portfolios
                </h2>
            </div>
            <div className="2xl:px-20 lg:px-12 px-4 flex flex-wrap items-start mt-4">
                <div className="mt-24">
                    <div className="flex items-end ">
                        <img tabIndex={0} src="pptpics/25.jpg" alt="girl with blue background" className="w-20 h-20 rounded-lg mr-6" />
                        <img tabIndex={0} src="pptpics/12.jpg" alt="guy winking" className="w-48 h-36 rounded-lg" />
                    </div>
                    <div className="flex items-center justify-end my-6">
                        <img tabIndex={0} src="pptpics/20.jpg" alt="guy smiling" />
                    </div>
                    <div className="flex items-start">
                        <img tabIndex={0} src="pptpics/15.jpg" alt="girl with bluw background" className="w-48 h-48 rounded-lg" />
                        <img tabIndex={0} src="pptpics/1.jpg" alt="guy with glasses" className="w-20 h-20 rounded-lg ml-6 flex-shrink-0 object-cover object-fit" />
                    </div>
                </div>
                <div className="ml-6 mt-32">
                    <img tabIndex={0} src="pptpics/14.jpg" className="w-72 h-80 rounded-lg" alt="guy with sunglasses" />
                    <div className="flex items-start mt-6">
                        <img tabIndex={0} src="pptpics/21.jpg" alt="girl  laughing" className="w-48 h-48 rounded-lg" />
                        <img tabIndex={0} src="pptpics/6.jpg" alt="guy with glasses" className="w-20 h-20 rounded-lg ml-6 object-cover object-fit" />
                    </div>
                </div>
                <div className="mt-14 ml-6">
                    <div className="lg:flex ">
                        <div>
                            <img tabIndex={0} src="pptpics/47.jpg" alt="group of friends" className="w-96 h-72 rounded-lg object-center object-fit" />
                        </div>
                        <div>
                            <div className="flex ml-6">
                                <img tabIndex={0} src="pptpics/35.jpg" className="w-20 h-20 rounded-lg mt-14" alt="man" />
                                <img tabIndex={0} src="pptpics/33.jpg" className="w-20 h-24 rounded-lg ml-6" alt="woman" />
                            </div>
                            <img tabIndex={0} src="pptpics/10.jpg" alt="boy with blonde hair" className="ml-6 mt-6 w-48 h-32 rounded-lg" />
                        </div>
                    </div>
                    <div className="mt-6 flex">
                        <img tabIndex={0} className="w-48 h-48 rounded-lg" src="pptpics/2.jpg" alt="young girl with red hair" />
                        <img tabIndex={0} className="w-72 h-56 rounded-lg ml-6" src="pptpics/48.jpg" alt="young girl with red hair" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MyApp;
