
export default function Home() {
    return (
      <div className="overflow-y-hidden">
        
        <div className="py-12 px-4 md:px-6 xl:px-20 2xl:container 2xl:mx-auto flex justify-center items-center flex-col md:flex-row md:space-x-6 xl:space-x-8">
         
          <div className="md:w-2/3">
            <img className src="/pptpics/tr3.jpg" alt="girls" />
          </div>
          <div className="flex mt-8 md:mt-0 md:w-1/3  flex-col jusitfy-start items-start">
          <h1 role="heading" tabIndex={0} className="text-6xl font-bold 2xl:leading-10 leading-0 text-center text-gray-800">
                    Work with renowned designers</h1>
            <p className="mt-4 ml-8 text-base  leading-normal text-gray-600">
            <ul class="list-disc">
  <li>Bharat Reshma</li>

<li>Dimple Kapoor</li>

<li>Harish k Vashisht</li>

<li>Nick Roshan</li>

<li>Archana Kochhar</li>

<li>Siddartha Tytler</li>

<li>Bibi Russell</li>

<li>Varun Bahel</li>
<li>Shruti Mangyaash</li>
<li>Aslam Khan</li>
<li>Dhruv Sehgal</li>
<li>Ken Ferns</li>
<li>Sharav Kumar</li>
<li>Rohit Kamra</li>
<li>Mehraab</li>
</ul>
            </p>
            
          </div>
        </div>
      </div>
  
    )
  }
  